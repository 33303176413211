import styled from "@emotion/styled";

import { Icon, NextLink } from "goi_common";
import { LARGEL_BUTTON_TYPE, LargeButton } from "goi_common";
import { ReactNode } from "react";

interface ErrorProps {
  children: ReactNode;
  buttonText?: string;
  buttonHref?: string;
  className?: string;
}

function Error({ children, buttonText, buttonHref, className }: ErrorProps) {
  return (
    <ErrorRoot className={className}>
      <Icon src="/error.svg" alt="error" width={80} height={80} />
      {children}
      {buttonHref && buttonText && (
        <FullWidthNextLink href={buttonHref}>
          <LargeButton buttonType={LARGEL_BUTTON_TYPE.DEFAULT}>{buttonText}</LargeButton>
        </FullWidthNextLink>
      )}
    </ErrorRoot>
  );
}

const ErrorRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 20px 80px;
  background: var(--gray50);
`;

const FullWidthNextLink = styled(NextLink)`
  width: 100%;
`;

export default Error;
